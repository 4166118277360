.line {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation-timing-function: cubic-bezier(0.56, 0, 0.72, 1);
}

.line-desktop {
  animation: dash-desktop 4s linear forwards;
}

.line-mobile {
  animation: dash-mobile 4.5s linear forwards;
}

@keyframes dash-desktop {
  0% {
    stroke-dashoffset: 1000;
  }
  90% {
    stroke-dashoffset: 430;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes dash-mobile {
  0% {
    stroke-dashoffset: 1000;
  }
  90% {
    stroke-dashoffset: 630;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
