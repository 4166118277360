:root {
  --family-primary: var(--font-aktivGrotesk), "Helvetica Neue", Arial,
    sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --cerise: #dc085c;
  --deep-blue: #020231;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--family-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

strong {
  font-weight: 700;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.wrapper {
  @apply max-w-8xl;
  @apply mx-auto;
  @apply w-full;
  @apply px-3;
}

/* md upwards */
@screen md {
  .wrapper {
    @apply px-12;
  }
}

@screen lg {
  .wrapper {
    @apply px-16;
  }
}

/* FAQ Dropdown on /membership */
.membership-faqs section.faq-row {
  border-bottom: none !important;
}
.membership-faqs .icon-wrapper {
  margin-top: 0px !important;
}

@screen md {
  .membership-faqs .icon-wrapper {
    margin-top: 9px !important;
  }
}

article .flourish-embed {
  @apply my-[32px];
}

.tp-modal {
  pointer-events: auto;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
/* allows users to interact with Cookiebot even if it's paywall at the background and body has pointer-events:none */
.CybotCookiebotDialogActive {
  pointer-events: auto;
}

/* Cookiebot branding on the banner */
a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
  display: none;
}

/* Cookiebot branding on the Privacy trigger */
#CookiebotWidget-widgetContent
  div.CookiebotWidget-body
  div.CookiebotWidget-main-logo {
  display: none;
}
