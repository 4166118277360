@tailwind base;
@tailwind components;

@tailwind utilities;

@layer base {
  body {
    @apply text-base;
  }
}
